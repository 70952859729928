import axios from 'axios';
import React, { createContext, useContext } from 'react';
import { HOST_API } from 'src/config-global';
import axiosInstance from 'src/utils/axios';


class CServerAPI {
  constructor(baseUrl, client = null) {
    this._baseUrl = baseUrl;
    this._client = axiosInstance;
    this._categoriesMapping = null;
    this._categoriesMappingObject = null;
  }

  _withAuthorization(headers = null) {
    // Add Authorization header if token is present
    headers = headers || {};
    const token = localStorage.getItem('accessToken');
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    return headers;
  }

  _useJson(headers = null) {
    headers = headers || {};
    headers['Content-Type'] = 'application/json';
    return headers;
  }

  async fetchLists() {
    const apiUrl = `/api/lists/`;
    const response = await this._client.get(apiUrl, {
      headers: this._withAuthorization(this._useJson())
    });

    return response.data;
  }

  async fetchPosts(influencerId, category, page, year = null, month = null) {
    const apiUrl = year && month
      ? `/api/influencer/posts?influencerId=${influencerId}&year=${year}&month=${month}&media_category=${category}&page=${page}`
      : `/api/influencer/posts?influencerId=${influencerId}&media_category=${category}&page=${page}`;

    const response = await this._client.get(apiUrl, {
      headers: this._withAuthorization(this._useJson())
    });

    return response.data;
  }

  async fetchTimeline(influencerId, category) {
    const apiUrl = `/api/influencer/${influencerId}/timeline?media_category=${category}`;
    const response = await this._client.get(apiUrl, {
      headers: this._withAuthorization(this._useJson())
    });

    return response.data;
  }

  async fetchSearchDataPdf(limit, payloadDataFinal, Authorization) {
    if (!limit) return;
    
    const headers = {
      Authorization,
      'Content-Type': 'application/json',
    };

    const apiUrl = `${this._baseUrl}/api/influencer/search?limit=${limit}`;

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers,
      body: JSON.stringify(payloadDataFinal),
    });

    if (response.headers.get('Content-Type').includes('application/pdf')) {
      return response.blob();
    } else {
      const errorData = await response.json();
      throw new Error(errorData);
    }
  }

  async _fetchCategoriesMapping() {
    /*
    Fetch the categories mapping from the server and cache it.
    */
    if (this._categoriesMapping) {
      return this._categoriesMapping;
    }
    const apiUrl = `/api/categories-mapping/`;
    const response = await this._client.get(apiUrl, {
      headers: this._withAuthorization(this._useJson())
    });
    this._categoriesMapping = response.data;
    return response.data;
  }

  async _mappedCategories(data) {
    /*
    Use data from the "/api/categories-mapping" endpoint to map the categories.
    */
    if (!data) {
      return data;
    }
    // check if is an object
    if (typeof data !== 'object') {
      throw new Error('Data should be an object');
    }
    const mapping = await this._fetchCategoriesMapping();
    // Map the categories and add extra fields
    const categories = {};
    for (const [key, item] of Object.entries(data)) {
      const extra = mapping[key] || {};
      categories[key] = {
        ...item,
        ...extra
      };
    }
    return categories;
  }

  async updateInfluencer({ id: influencerId, ...data }) {
    /*
    This function will update the influencer data in the server.
    The data object should contain the fields:
      id, age, name, region, pronoun, gender, 
      profile_pic, social_profiles, summary, 
      is_summary_generated
    */
    if (!influencerId) {
      throw new Error('Influencer ID is required');
    }
    const apiUrl = `/api/influencer/${influencerId}/`;
    const response = await this._client.patch(apiUrl, data, {
      headers: this._withAuthorization(this._useJson())
    });

    return response.data;
  }

  async getInfluencerData(influencerId) {
    /*
    This function will fetch the influencer data from the server.
    Influencer ID is required.
    */
    if (!influencerId) {
      throw new Error('Influencer ID is required');
    }
    const apiUrl = `/api/influencer/${influencerId}/`;
    const response = await this._client.get(apiUrl, {
      headers: this._withAuthorization(this._useJson())
    });

    const data = response.data;
    const mappedScores = await this.getCategoriesByIds([influencerId]);
    // Map the categories
    data.scores = mappedScores[0][influencerId];
    return data;
  }

  async getInfluencersData(limit, value, isRotated, page, platforms, setFilterData, region, gender, followersReach, sliderValues, influencerDisplayName, isMyAgency) {
    const payloadDataFinal = {};
    const platformPayloadData = {};

    if (region) {
      payloadDataFinal.region = region;
    }
    if (gender) {
      payloadDataFinal.gender = gender;
    }
    if (followersReach) {
      payloadDataFinal.followersReach = Number(followersReach);
    }

    if (platforms.facebook) {
      platformPayloadData.facebook = platforms.facebook;
    }
    if (platforms.instagram) {
      platformPayloadData.instagram = platforms.instagram;
    }
    if (platforms.twitter) {
      platformPayloadData.twitter = platforms.twitter;
    }
    if (platforms.tiktok) {
      platformPayloadData.tiktok = platforms.tiktok;
    }
    if (platforms.youtube) {
      platformPayloadData.youtube = platforms.youtube;
    }
    payloadDataFinal.platform = platformPayloadData;
    payloadDataFinal.contentAlignment = sliderValues;
    payloadDataFinal.influencerDisplayName = influencerDisplayName;
    payloadDataFinal.isFiltered = true;
    payloadDataFinal.isMyAgency = isMyAgency;

    /*
    This function will fetch the influencer data from the server.
    Influencer ID is required.
    */
    const payloadDataFinalFilter = {
      isMyAgency: isMyAgency,
      isFiltered: true,
      platform: platformPayloadData,
      region: region,
      followersReach: Number(followersReach),
      gender: gender,
      influencerDisplayName: influencerDisplayName,
      contentAlignment: sliderValues
    };

    setFilterData(payloadDataFinalFilter);

    const body = payloadDataFinal;

    const apiUrl = `/api/influencer/search?limit=${limit}&page=${page || 1}&ordering=${isRotated ? '' : '-'}${value}`;
    const response = await this._client.post(apiUrl, body, {
      headers: this._withAuthorization(this._useJson()),
    })

    const data = response.data;

    const influencerIds = data.influencers.map(influencer => influencer.id);

    const mappedScores = await this.getCategoriesByIds(influencerIds);

    data.influencers = data.influencers.map(influencer => {
      const newScores = mappedScores.find(score => score[influencer.id]);
      if (newScores) {
        influencer.scores = newScores[influencer.id];
      }
      return influencer;
    });

    return data;
  }

  async getCategoriesByIds(influencer_ids) {
    if (!influencer_ids) {
      throw new Error('Influencers ID is required');
    }
    if (influencer_ids.length === 0) {
      return;
    }
    const apiUrl = `/api/influencer/categories-mapping/`;
    const response = await this._client.post(apiUrl, { influencer_ids }, {
      headers: this._withAuthorization(this._useJson())
    });

    const data = response.data;
    return data;
  }

  async mappedCategoriesByIds(categories) {
    if (!ids) {
      throw new Error('Influencers ID is required');
    }
    const apiUrl = `/api/influencer/categories-mapping/`;
    const response = await this._client.get(apiUrl, {
      headers: this._withAuthorization(this._useJson())
    });

    const data = response.data;
    return data;
  }

  async fetchInfluencerPdf(influencerId) {
    /*
    This function will fetch the influencer data from the server in PDF format.
    Influencer ID is required.
    */
    if (!influencerId) {
      throw new Error('Influencer ID is required');
    }
    const headers = this._withAuthorization();
    const apiUrl = `/api/influencer/${influencerId}/?pdf=true`;
    const response = await this._client.get(apiUrl, { headers, responseType: 'blob' });

    // TODO: Verify that this is correct way with the axios. Data is already in blob format.
    if (response.headers['content-type'].includes('application/pdf')) {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      return blob;
    }

    // If the response is not a PDF, then it is an error message
    // Convert the blob to text and throw an error
    async function blobToText(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function () { resolve(reader.result); };
        reader.onerror = function () { reject(reader.error); };
        reader.readAsText(blob);
      });
    }
    const text = await blobToText(response.data);
    const data = JSON.parse(text);
    throw new Error(data.message || 'Unknown error');
  }


  async addToList({ listId, influencerId }) {
    /*
    This function will add the influencer to the list.
    List ID and Influencer ID are required.
    */
    if (!listId) {
      throw new Error('List ID is required');
    }
    if (!influencerId) {
      throw new Error('Influencer ID is required');
    }
    const apiUrl = `/api/lists/${listId}/influencers/`;
    const body = { influencer_ids: [influencerId] };
    const response = await this._client.post(apiUrl, body, {
      headers: this._withAuthorization(this._useJson())
    });
    return response.data;
  }

  async removeFromList({ listId, influencerId }) {
    /*
    This function will remove the influencer from the list.
    List ID and Influencer ID are required.
    */
    if (!listId) {
      throw new Error('List ID is required');
    }
    if (!influencerId) {
      throw new Error('Influencer ID is required');
    }
    const apiUrl = `/api/lists/${listId}/influencers/${influencerId}/`;
    const response = await this._client.delete(apiUrl, {
      headers: this._withAuthorization(this._useJson())
    });
    return response.data;
  }

  async changeBookmark(influencerId) {
    /*
    This function will change the bookmark status of the influencer.
    On success, endpoint will return:
      {"isBookmarked": is_bookmarked}

    function will return the is_bookmarked value.
    */
    if (!influencerId) {
      throw new Error('Influencer ID is required');
    }
    const apiUrl = `/api/lists/influencers/${influencerId}/toggle-bookmark/`;
    const response = await this._client.patch(apiUrl, {}, {
      headers: this._withAuthorization(this._useJson())
    });
    return response.data.isBookmarked;
  }

  async mapping() {
    if (!this._categoriesMappingObject) { // if not already fetched
      const flatData = await this._fetchCategoriesMapping();
      // data is like: {category: {title, description, parent, children, is_leaf}}
      // convert it to a nested structure:
      //   title - item title
      //   description - item description
      //   children - array of nested items

      let hierarchy = {};
      let nodes = {};

      // Initialize nodes and their structure
      for (let key in flatData) {
        nodes[key] = {
          title: flatData[key].title,
          description: flatData[key].description,
          children: {}
        };
      }

      // Build the hierarchy
      for (let key in flatData) {
        let parent = flatData[key].parent;
        if (parent === null) {
          hierarchy[key] = nodes[key];
        } else {
          nodes[parent].children[key] = nodes[key];
        }
      }

      this._categoriesMappingObject = hierarchy;
    }

    return this._categoriesMappingObject;
  }

  async userDashboard() {
    const apiUrl = `/stats/user-dashboard/`;
    const response = await this._client.get(apiUrl, {
      headers: this._withAuthorization(this._useJson())
    });
    return response.data;
  }

  async findInList({ listId, filters, page, order_by }) {
    /*
    Fetch the list of influencers based on the applied filters.
    */
    const apiUrl = `/api/lists/${listId}/influencers/search/?page=${page}&ordering=${order_by}`;
    const response = await this._client.post(apiUrl, { filters }, {
      headers: this._withAuthorization(this._useJson())
    });

    const data = response.data;

    const influencerIds = data.results.map(influencer => influencer.id);

    const mappedScores = await this.getCategoriesByIds(influencerIds);

    data.results = data.results.map(influencer => {
      const newScores = mappedScores.find(score => score[influencer.id]);
      if (newScores) {
        influencer.scores = newScores[influencer.id];
      }
      return influencer;
    });

    return data;
  }

  async createNewList(data) {
    /*
    Create a new list with the given data.
    */
    const apiUrl = `/api/lists/`;
    const response = await this._client.post(apiUrl, data, {
      headers: this._withAuthorization(this._useJson())
    });
    return response.data;
  }

  async renameList(listId, data) {
    /*
    Rename the list with the given list ID and data.
    */
    const apiUrl = `/api/lists/${listId}/`;
    const response = await this._client.patch(apiUrl, data, {
      headers: this._withAuthorization(this._useJson())
    });
    return response.data;
  }

  async deleteList(listId) {
    /*
    Delete the list with the given list ID.
    */
    const apiUrl = `/api/lists/${listId}/`;
    const response = await this._client.delete(apiUrl, {
      headers: this._withAuthorization(this._useJson())
    });
    return response.data;
  }
}


const ServerAPIContext = createContext();

export const useServerAPI = () => {
  return useContext(ServerAPIContext);
};

export const ServerAPIProvider = ({ children }) => {
  const apiObject = React.useMemo(() => new CServerAPI(HOST_API), [HOST_API]);

  return (
    <ServerAPIContext.Provider value={apiObject}>
      {children}
    </ServerAPIContext.Provider>
  );
};

export { CServerAPI };